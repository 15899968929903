import request from '@/utils/request'

// 查询活动奖励周边列表
export function listActivitySide(query) {
  return request({
    url: '/activity/activity/side/list',
    method: 'get',
    params: query
  })
}

// 查询活动奖励周边详细
export function getActivitySide(id) {
  return request({
    url: '/activity/activity/side/' + id,
    method: 'get'
  })
}

// 新增活动奖励周边
export function addActivitySide(data) {
  return request({
    url: '/activity/activity/side',
    method: 'post',
    data: data
  })
}

// 修改活动奖励周边
export function updateActivitySide(data) {
  return request({
    url: '/activity/activity/side',
    method: 'put',
    data: data
  })
}

// 删除活动奖励周边
export function delActivitySide(id) {
  return request({
    url: '/activity/activity/side/' + id,
    method: 'delete'
  })
}
