<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="逻辑删除标记 1:删除,0:未删除" prop="isDeleted" v-if="formType === 1">-->
      <!--        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记 1:删除,0:未删除" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="备注" prop="remark" >-->
      <!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">-->
      <!--        <a-input v-model="form.version" placeholder="请输入乐观锁" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="活动id, activity_activity-id" prop="activityId" >-->
      <!--        <a-input v-model="form.activityId" placeholder="请输入活动id, activity_activity-id" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="周边物品名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入周边物品名称"/>
      </a-form-model-item>
      <a-form-model-item label="套餐金额" prop="payAmount">
        <!--        <a-input v-model="form.payAmount" placeholder="请输入套餐金额" />-->
        <a-input v-model="form.payAmount" placeholder="请输入套餐金额" @input.native="formatNum($event,index)"/>
      </a-form-model-item>
      <a-form-model-item label="活动奖励" prop="img">
        <OssMultiUploadSingle v-model="form.img" type="img" :maxSize="10"></OssMultiUploadSingle>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getActivitySide, addActivitySide, updateActivitySide} from '@/api/activity/activitySide'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import OssMultiUploadSingle from '@/views/script/script/modules/OssMultiUploadSingle.vue'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    OssMultiUpload, OssMultiUploadSingle
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        activityId: null,

        name: null,

        payAmount: null,

        img: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        img: [
          {required: true, message: '活动奖励图片不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ],

        name: [
          {required: true, message: '周边物品名称不能为空', trigger: 'blur'}
        ],

        payAmount: [
          {required: true, message: '套餐金额不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        activityId: null,

        name: null,

        payAmount: null,

        img: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getActivitySide(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateActivitySide(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addActivitySide(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
